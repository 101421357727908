import { getBlogArticles } from '@/utility/fetchAPI'

const initialState = {
  id: '',
  list: [],
}

export const state = () => initialState

export const getters = {
  GET_BLOGS: (state) => state.list,
}

export const mutations = {
  SET_BLOGS(state, payload) {
    state.list = Object.assign([], initialState.list, payload)
  },
}

export const actions = {
  async INDEX({ commit }) {
    await getBlogArticles.then((response) => {
      commit('SET_BLOGS', response.data.data)
    })
  },
}
