
export default {
  props: {
    webdesigner: {
      type: Boolean,
    },
    designer: {
      type: Boolean,
    },
    director: {
      type: Boolean,
    },
    creator: {
      type: Boolean,
    },
    marketer: {
      type: Boolean,
    },
    engineer: {
      type: Boolean,
    },
    sidejob: {
      type: Boolean,
      default: false,
    },
    counselingLpHtmlcss: {
      type: Boolean,
      default: false,
    },
  },
}
