export default function ({ $axios, $sentry }, inject) {
  const api = $axios.create({
    baseURL: process.env.apiBaseUrl || 'http://localhost:3000/api/v1/',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  })

  api.interceptors.request.use(
    (request) => {
      return request
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  api.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      $sentry.captureException(error)
      // Promise.reject(error) してしまうと、Promise.allとの相性が悪いため、ここで例外をキャッチし、
      // axiosによって整形されたerrorレスポンスを返している。
      return error.response

      // 相性が悪い理由:
      // 1. Promise.all でまとめたPromiseのうちrejectされたものがあると、即座にcatchが走ってしまう。（他のPromiseの処理に依存しないためコントロールできない）
      // 2. 複数rejectがあっても、最初の一つしかcatchが走らない
    }
  )

  inject('api', api)
}
