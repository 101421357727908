import { render, staticRenderFns } from "./TheBuCommonHeader.vue?vue&type=template&id=509ab0a2&scoped=true&lang=pug"
import script from "./TheBuCommonHeader.vue?vue&type=script&lang=js"
export * from "./TheBuCommonHeader.vue?vue&type=script&lang=js"
import style0 from "./TheBuCommonHeader.vue?vue&type=style&index=0&id=509ab0a2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "509ab0a2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BuCommonHeaderLogo: require('/vercel/path0/components/Bu/BuCommon/BuCommonHeaderLogo/BuCommonHeaderLogo.vue').default,BuCommonHeaderMenuPc: require('/vercel/path0/components/Bu/BuCommon/BuCommonHeaderMenuPc/BuCommonHeaderMenuPc.vue').default,BuCommonHeaderMenuSp: require('/vercel/path0/components/Bu/BuCommon/BuCommonHeaderMenuSp/BuCommonHeaderMenuSp.vue').default})
