import { getCurrentUser } from '@/utility/fetchAPI'

const initialState = {
  currentUser: null
}

export const state = () => initialState

export const getters = {
  GET_CURRENT_USER: (state) => state.currentUser,
}

export const mutations = {
  SET_CURRENT_USER(state, payload) {
    if (payload) state.currentUser = payload.attributes
  },
}

export const actions = {
  async getCurrentUser({ commit }) {
    await getCurrentUser(this.$api).then((response) => {
      commit('SET_CURRENT_USER', response.data)
    })
  },
}
