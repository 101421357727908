
import TheBuCommonHeaderSimple from '~/components/Bu/BuCommon/TheBuCommonHeaderSimple'
import TheTeCommonFooterSimple from '~/components/Te/TeCommon/Footer/TheTeCommonFooterSimple/TheTeCommonFooterSimple'

export default {
  components: {
    TheBuCommonHeaderSimple,
    TheTeCommonFooterSimple,
  },
}
