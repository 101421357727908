// vue-scrollto
// ref: https://github.com/rigor789/vue-scrollto

import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'

Vue.use(VueScrollTo, {
  // オプションが効かないのでnuxt.configで設定
  // container: "body",
  // duration: 500,
  // easing: "ease",
  // offset: -70,
  // force: true,
  // cancelable: true,
  // onStart: false,
  // onDone: false,
  // onCancel: false,
  // x: false,
  // y: true
})
