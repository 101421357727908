import { API_PATH } from '~/constants/api-path'
import { queryConfigToString } from '~/utility/utility'

export const getEntryDiscountTimeLimit = (api) => {
  return new Promise((resolve, reject) => {
    api
      .get(API_PATH.ENTRY_DISCOUNT_TIME_LIMIT)
      .then((response) => {
        // 考慮の余地あり Promise.all を使う箇所との兼ね合いなので、要調査
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response)
        }
      })
      .catch((error) => reject(error))
  })
}

// ブートキャンプの直近のもの取得
export const getLatestCohort = (api) => {
  return new Promise((resolve, reject) => {
    api
      .get(API_PATH.CHORTS_LATEST)
      .then((response) => {
        // 考慮の余地あり Promise.all を使う箇所との兼ね合いなので、要調査
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

// 開催中の受け放題の一番最初のものを取得
export const getFirstOpenedWholeCohort = (api) => {
  return new Promise((resolve, reject) => {
    api
      .get(API_PATH.WHOLE_COHORTS_FIRST_OPENED)
      .then((response) => {
        // 考慮の余地あり Promise.all を使う箇所との兼ね合いなので、要調査
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

// 受け放題へのエントリー
export const postWholeEntry = (api, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.WHOLE_COHORTS}/entries`, body)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const getCohorts = (api, queryConfig) => {
  return new Promise((resolve, reject) => {
    api
      .get(API_PATH.CHORTS + queryConfigToString(queryConfig))
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const getPromotionCohorts = (api, screenName) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.COURSE}/${screenName}/promotion_target_cohorts`)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject((response?.data))
        }
      })
      .catch((error) => reject(error))
  })
}

export const getPromotionPlans = (api, screenName) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.COURSE}/${screenName}/promotion_target_plans`)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject((response?.data))
        }
      })
      .catch((error) => reject(error))
  })
}

// ブログ記事取得
export const getBlogArticles = (api) => {
  return new Promise((resolve, reject) => {
    api
      .get(API_PATH.BLOG_ARTICLES)
      .then((response) => {
        // 考慮の余地あり Promise.all を使う箇所との兼ね合いなので、要調査
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

// プロモーション（キャンペーン）取得
export const getCurrentPromotion = (api) => {
  return new Promise((resolve, reject) => {
    api
      .get(API_PATH.PROMOTION_CURRENT)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

// コース一覧の取得
export const getCourses = (api, queryConfig) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.COURSE}` + queryConfigToString(queryConfig))
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const getCourse = (api, path, queryConfig) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.COURSE}/${path}` + queryConfigToString(queryConfig))
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const getPack = (api, path, queryConfig) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.PACK}/${path}` + queryConfigToString(queryConfig))
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const getPackPlans = (api, path, queryConfig) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.PACK}/${path}/pack_plans` + queryConfigToString(queryConfig))
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const postCourseEntry = (api, path, body, persist = true) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.COURSE}/${path}/entries?persist=${persist}`, body)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const postPackEntry = (api, path, body, persist = true) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.PACK}/${path}/entries?persist=${persist}`, body)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const postMiniCourseEntry = (api, path, body, persist = true) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.MINI_COURSE}/${path}/entries?persist=${persist}`, body)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const postTrialCourseEntry = (api, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.TRIAL_ENTRY}`, body)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const postBriefingEntry = (api, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.BRIEFING_ENTRY}`, body)
      .then((response) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const postMoocresBriefingEntry = (api, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.MOOCRES_BRIEFING_ENTRY}`, body)
      .then((response) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const postLpSCoverBriefingEntry = (api, path, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.BRIEFING_ENTRY}/${path}/lp_entries`, body)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const postLpSProgrammingCoverBriefingEntry = (api, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.BRIEFING_ENTRY}/lps`, body)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const postJobsCandidateEntry = (api, path, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.JOBS}/${path}/candidates`, body)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const getJobsCandidateFormInfo = (api) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.JOBS}/candidates`)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

// フォーム中の項目の取得
export const getTrialMiniCourseSchedule = (api, path, query = {}) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.MINI_COURSE}/${path}/schedules`, {params: query})
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

// 無料相談の空き状況の取得
export const getTrialMiniCourseScheduleTable = (api, path, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.MINI_COURSE}/${path}/view_appointment_form`, body)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const postJobsCandidateProfile = (api, token, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.JOBS}/candidates/${token}/profile`, body)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const postTrialMiniCourseSchedule = (api, path, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.MINI_COURSE}/${path}/schedules`, body)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const getCheckJobsProfileCandidateStatus = (api, token) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.JOBS}/candidates/${token}/profile_status`)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

// -- scriptタグのため情報取得 -- //

export const getInfoForBriefingEntryScripts = (api) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.BRIEFING_ENTRY}/script_info`)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const getInfoForLpSBriefingEntryScripts = (api, path) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/script/${API_PATH.BRIEFING_ENTRY}/lps/${path}`)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

// -- セミナー動画申し込み -- //
export const postSeminarEntry = (api, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.SEMINAR_ENTRY}`, body)
      .then((response) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const getBizTeam = (api, team, body) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.BIZ}/teams/${team}`, body)
      .then((response) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

// -- お問合せ送信 -- //
export const postContact = (api, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.CONTACT}`, body)
      .then((response) => {
        if (response?.status === 200 || response?.status === 204) {
          resolve(response?.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const getContactCategoryType = (api, body) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.CONTACT}`, body)
      .then((response) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const postYahooEntry = (api, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.YAHOO_ENTRY}`, body)
      .then((response) => {
        if (response?.status === 200 || response?.status === 204) {
          resolve(response?.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const postYahooNotification = (api, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.YAHOO_NOTIFICATION}`, body)
      .then((response) => {
        if (response?.status === 200 || response?.status === 204) {
          resolve(response?.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const getTopics = (api, body) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.TOPICS}`, body)
      .then((response) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const getCurrentUser = (api) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.USER_CURRENT}`)
      .then((response) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  })
}

export const postSession = (api, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.SESSION}`, body)
      .then((response) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  });
}

export const postYahooPasswordForgot = (api, body) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.YAHOO_PASSWORD_FORGOT}`, body)
      .then((response) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  });
}

export const postContactBiz = (api, body) => {
  return new Promise((resolve, reject) => {
    api.post(`${API_PATH.YAHOO_CONTACT_BIZ}`, body)
      .then((response) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error) => reject(error))
  });
}
