import { render, staticRenderFns } from "./TheBuCommonHeaderSimple.vue?vue&type=template&id=00427624&scoped=true&lang=pug"
var script = {}
import style0 from "./TheBuCommonHeaderSimple.vue?vue&type=style&index=0&id=00427624&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00427624",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BuCommonHeaderLogo: require('/vercel/path0/components/Bu/BuCommon/BuCommonHeaderLogo/BuCommonHeaderLogo.vue').default})
