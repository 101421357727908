
import TheYaTeCommonHeader from '~/components/YaTe/YaTeCommon/Header/TheYaTeCommonHeader.vue'
import TheYaTeCommonFooterSimple from '~/components/YaTe/YaTeCommon/Footer/TheYaTeCommonFooterSimple.vue'

export default {
  components: {
    TheYaTeCommonHeader,
    TheYaTeCommonFooterSimple,
  },
  head: {
    link: [
      {
        hid: 'favicon',
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon-yate.ico',
      },
    ],
  },
}
