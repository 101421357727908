
import TheTeCommonHeaderLpS from '~/components/Te/TeCommon/Header/TheTeCommonHeaderLpS/TheTeCommonHeaderLpS'
import TheTeCommonFooterSimple from '~/components/Te/TeCommon/Footer/TheTeCommonFooterSimple/TheTeCommonFooterSimple'

export default {
  components: {
    TheTeCommonHeaderLpS,
    TheTeCommonFooterSimple,
  },
}
