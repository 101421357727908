export default ({ _ }: any, inject: any) => {
  inject('addDays', (date: Date, days: number) => {
    if (!date) return null
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
  })

  inject('displayDate', (date: Date | null) => {
    if (!date) return '-'
    const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'][
      date.getDay()
    ]
    return `${date.getFullYear()}年${
      date.getMonth() + 1
    }月${date.getDate()}日(${dayOfWeek})`
  })
}
