
import TheBuCommonHeader from '~/components/Bu/BuCommon/TheBuCommonHeader/TheBuCommonHeader'
import TheTeCommonFooterSimple from '~/components/Te/TeCommon/Footer/TheTeCommonFooterSimple/TheTeCommonFooterSimple'

export default {
  components: {
    TheBuCommonHeader,
    TheTeCommonFooterSimple,
  },
}
