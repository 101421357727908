
import TheTeCommonHeaderSimple from '~/components/Te/TeCommon/Header/TheTeCommonHeaderSimple/TheTeCommonHeaderSimple'
import TheTeCommonFooterSimple from '~/components/Te/TeCommon/Footer/TheTeCommonFooterSimple/TheTeCommonFooterSimple'

export default {
  components: {
    TheTeCommonHeaderSimple,
    TheTeCommonFooterSimple,
  },
}
