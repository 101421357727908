import { Plugin, Context } from '@nuxt/types'

/* eslint-disable camelcase */
export interface MarketingParamsData {
  session_id: string,
  session_started_at: string,
  source_domain: string,
  utm_params: {
    utm_source: string,
    utm_medium: string,
    utm_campaign: string,
  }
}
/* eslint-enable */

export class MarketingParams {
  static readonly KEY: string = 'billy/marketingParams';

  get(): MarketingParamsData {
    const data = localStorage.getItem(MarketingParams.KEY)
    if (data) {
      return JSON.parse(data)
    }
    return {
      session_id: '',
      session_started_at: '',
      source_domain: '',
      utm_params: {
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
      }
    }
  }

  set(data: MarketingParamsData): void {
    localStorage.setItem(MarketingParams.KEY, JSON.stringify(data))
  }

  remove(): void {
    localStorage.removeItem(MarketingParams.KEY)
  }
}

const MarketingParamsPlugin: Plugin = (context: Context, inject) => {
  const marketingParams = new MarketingParams
  const utmSourceParams = context.query.utm_source
  if (utmSourceParams !== undefined) {
    const utmSource = Array.isArray(utmSourceParams) ? utmSourceParams[0] : utmSourceParams
    const utmMediumParams = context.query.utm_medium
    const utmMedium = Array.isArray(utmMediumParams) ? utmMediumParams[0] : utmMediumParams
    const utmCampaignParams = context.query.utm_campaign
    const utmCampaign = Array.isArray(utmCampaignParams) ? utmCampaignParams[0] : utmCampaignParams
    const referrer = document.referrer
    let sourceDomain = ''
    if (referrer) {
      const url = new URL(referrer)
      sourceDomain = url.hostname
    }
    marketingParams.set({
      session_id: (<any>crypto).randomUUID(),
      session_started_at: new Date().toISOString(),
      source_domain: sourceDomain,
      utm_params: {
        utm_source: utmSource || '',
        utm_medium: utmMedium || '',
        utm_campaign: utmCampaign || '',
      }
    })
  }

  inject('marketingParams', marketingParams)
}

export default MarketingParamsPlugin
