
import TheTeCommonHeader from '~/components/Te/TeCommon/Header/TheTeCommonHeader/TheTeCommonHeader'
import TheTeCommonFooterRowTwo from '~/components/Te/TeCommon/Footer/TheTeCommonFooterRowTwo/TheTeCommonFooterRowTwo'

export default {
  components: {
    TheTeCommonHeader,
    TheTeCommonFooterRowTwo,
  },
  async mounted() {
    await this.$store.dispatch('user/getCurrentUser')
  },
}
