export default ({ $device }, inject) => {

  const deviceSize = () => {
    if ($device.isDesktop) {
      return 'd'
    } else if ($device.isTablet) {
      return 't'
    } else {
      return 'm'
    }
  }

  inject('deviceSize', deviceSize)
}
