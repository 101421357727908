
import Vue from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'
import TheTeCommonHeaderMenuSp from '~/components/Te/TeCommon/Header/TheTeCommonHeaderMenuSp/TheTeCommonHeaderMenuSp.vue'
import TheTeCommonHeaderMenuPc from '~/components/Te/TeCommon/Header/TheTeCommonHeaderMenuPc/TheTeCommonHeaderMenuPc.vue'

interface Data {}

interface Methods {}

interface Computed {
  currentUser: any | null
  paid: boolean
  appliedHtmlcssTrial: boolean
}

interface Props {}

const options: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  Data,
  Methods,
  Computed,
  Props
> = {
  computed: {
    currentUser() {
      return (this as any).$store.getters['user/GET_CURRENT_USER']
    },
    paid() {
      return this.currentUser ? this.currentUser.paid : false
    },
    appliedHtmlcssTrial() {
      return this.currentUser ? this.currentUser.applied_htmlcss_trial : false
    },
  },
  components: {
    TheTeCommonHeaderMenuSp,
    TheTeCommonHeaderMenuPc,
  },
}

export default Vue.extend(options)
