export default (_, inject) => {

  const karteSendEvent = (event, params) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'karte_event',
      karte_event: event,
      karte_params: params,
    })
  }

  inject('karteSendEvent', karteSendEvent)
}
