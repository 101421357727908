
import TheBuCommonHeaderSimpleNoLink from '~/components/Bu/BuCommon/TheBuCommonHeaderSimpleNolink'
import TheTeCommonFooterSimple from '~/components/Te/TeCommon/Footer/TheTeCommonFooterSimple/TheTeCommonFooterSimple.vue'

export default {
  components: {
    TheBuCommonHeaderSimpleNoLink,
    TheTeCommonFooterSimple,
  },
}
