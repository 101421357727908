
import BuCommonHeaderMenuPc from '~/components/Bu/BuCommon/BuCommonHeaderMenuPc/BuCommonHeaderMenuPc'
import BuCommonHeaderMenuSp from '~/components/Bu/BuCommon/BuCommonHeaderMenuSp/BuCommonHeaderMenuSp'

export default {
  components: {
    BuCommonHeaderMenuPc,
    BuCommonHeaderMenuSp,
  },
}
