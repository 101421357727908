
import TheTeBriefingHeader from '~/components/Te/TeBriefing/TheTeBriefingHeader/TheTeBriefingHeader'
import TheTeCommonFooterSimple from '~/components/Te/TeCommon/Footer/TheTeCommonFooterSimple/TheTeCommonFooterSimple'

export default {
  components: {
    TheTeBriefingHeader,
    TheTeCommonFooterSimple,
  },
}
