
import TheBuCommonHeader from '~/components/Bu/BuCommon/TheBuCommonHeader/TheBuCommonHeader'
import TheBuCommonFooter from '~/components/Bu/BuCommon/TheBuCommonFooter'

export default {
  components: {
    TheBuCommonHeader,
    TheBuCommonFooter,
  },
}
