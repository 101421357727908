
import TheTeCommonHeader from '~/components/Te/TeCommon/Header/TheTeCommonHeader/TheTeCommonHeader.vue'
import TheTeCommonFooterRowOne from '~/components/Te/TeCommon/Footer/TheTeCommonFooterRowOne/TheTeCommonFooterRowOne.vue'

export default {
  components: {
    TheTeCommonHeader,
    TheTeCommonFooterRowOne,
  },
}
