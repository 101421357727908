import { render, staticRenderFns } from "./TheYaTeCommonNote.vue?vue&type=template&id=e268c7c4&scoped=true&lang=pug"
var script = {}
import style0 from "./TheYaTeCommonNote.vue?vue&type=style&index=0&id=e268c7c4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e268c7c4",
  null
  
)

export default component.exports