import Vue from 'vue';

const plugin = {
  install(Vue, _) {
    Vue.mixin({
      updated() {
        if (this.$metaInfo) {
          document.dispatchEvent(new Event('karte-blocks-force-activate'));
        }
      }
    })
  }
};

Vue.use(plugin);
